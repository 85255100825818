.react-datepicker__input-container > input {
    height: 30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: 0;
    border-left: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-top: 1px solid #979797;
    padding: 8px;
    font-size: 16px;
    font-family: "Roboto";
    cursor: pointer;
}
